// Generated by Framer (cf240c2)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-N2K2i"

const variantClassNames = {YBXLvBnGj: "framer-v-km41dm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "YBXLvBnGj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-km41dm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YBXLvBnGj"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNjAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-line-height": "150%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(12, 12, 13))"}}>Contact Us</motion.p></React.Fragment>} className={"framer-1a1fr89"} data-framer-name={"Contact Us"} fonts={["GF;Inter Tight-600"]} layoutDependency={layoutDependency} layoutId={"h9Nbk9ySg"} style={{"--extracted-r6o4lv": "rgb(12, 12, 13)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N2K2i.framer-1wxfx5t, .framer-N2K2i .framer-1wxfx5t { display: block; }", ".framer-N2K2i.framer-km41dm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 16px; position: relative; width: min-content; }", ".framer-N2K2i .framer-1a1fr89 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-N2K2i.framer-km41dm { gap: 0px; } .framer-N2K2i.framer-km41dm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-N2K2i.framer-km41dm > :first-child { margin-left: 0px; } .framer-N2K2i.framer-km41dm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 112.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMJ5AGguzz: React.ComponentType<Props> = withCSS(Component, css, "framer-N2K2i") as typeof Component;
export default FramerMJ5AGguzz;

FramerMJ5AGguzz.displayName = "Button/Primary Button";

FramerMJ5AGguzz.defaultProps = {height: 56, width: 112.5};

addFonts(FramerMJ5AGguzz, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqWSRToK8EPg.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})